.segura__btn__reagentes{
	display: flex;
	justify-content: center;
	padding-top: 30px;
}
.formulario__pesquisa{
	display: flex;
	max-width: 650px;
	width: 100%;
	margin-bottom: 10px;

	input{
		width: 100%;
		display: block;
		border: 1px solid #042f76;
		color: #042f76;
		background-color: #fff;
		padding: 10px 15px;

		&::placeholder{
			color: #042f76;
		}
	}
	.pesquisa__btn{
		width: 46px;
		height: 46px;
		background-color: #042f76;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
	}
}
.alfabeto{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 40px;
	& > * {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border: 1px solid #042f76;
		margin: 0px 3px;
		color: #042f76;
		font-family: 'open-sans-bold';
	}
}
.reagente__card{
	border: 2px solid #042f76;
	margin-bottom: 20px;
	padding: 20px;
}
.reagente__titulo{
	color: #042f76;
	font-family: 'open-sans-bold';
	margin-bottom: 5px;
}
.reagente__sub{
	font-family: 'open-sans-bold';
	margin-bottom: 5px;
}
