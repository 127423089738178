.card-produtos{
	width: 100%;
	max-width: 255px;
	margin-right: auto;
	margin-left: auto;
	text-align: center;

	.nome{
		font-size: 15px;
		line-height: 19px;
		font-family: $ops-bold;
		color: #1d1d1d;
		margin: 5px 0;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.texto{
		font-size: 14px;
		line-height: 18px;
		color: #5c5c5c;
		height: 36px;
		@include line-clamp(2);
	}
	.botao{
		width: 100%;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background-color: #246ce4;
		color: #fff;
		font-size: 15px;
		font-family: $ops-bold;
		line-height: 1;
		margin-top: 10px;
		transition: all 0.3s ease;

		&:hover{
			background-color: $azul;
			color: #fff;
		}
	}
}
// .card-produtos

.card-novidades{

	.nome{
		font-size: 15px;
		line-height: 19px;
		font-family: $ops-bold;
		color: #1d1d1d;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-bottom: 5px;
	}
	.texto{
		font-size: 14px;
		line-height: 18px;
		color: #5c5c5c;
		max-height: 126px;
		@include line-clamp(7);
	}

	@include media-breakpoint-up(sm) {
		display: flex;
		align-items: center;

		.img{
			min-width: 215px;
			margin-right: 15px;
		}
	}
	@include media-breakpoint-only(xs) {
		text-align: center;
		.img{
			margin-bottom: 5px;
		}
	}
}
// .card-novidades

.card-curiosidade{
	.data{
		font-size: 14px;
		color: #5c5c5c;
		line-height: 1;
		margin: 10px 0 5px 0;
		font-family: $ops-italic;
		display: flex;
		align-items: center;

		i.fas{
			color: #000;
			margin-right: 5px;
		}
	}
	.nome{
		font-family: $ops-extrabold;
		font-size: 18px;
		line-height: 22px;
		color: #1d1d1d;
	}
	.texto{
		font-size: 14px;
		line-height: 18px;
		color: #5c5c5c;
		margin: 5px 0;
	}
	.botao{
		display: inline-flex;
		align-items: center;
		font-size: 14px;
		color: #1d1d1d;
		font-family: $ops-bold;
		position: relative;
		line-height: 1;

		&:after{
			content: '';
			position: relative;
			display: block;
			width: 40px;
			height: 2px;
			background-color: currentColor;
			margin: auto 0px auto 10px;
		}
	}
}
// .card-curiosidade

.card-downloads{
	width: 100%;
	max-width: 255px;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	border: 2px solid transparent;
	margin-bottom: 40px;

	.icone{
		padding-top: 20px;
		font-size: 60px;
		line-height: 1;
		color: #212121;
		margin-bottom: 10px;
	}
	.nome{
		font-family: $ops-bold;
		font-size: 15px;
		line-height: 19px;
		color: #1d1d1d;
	}
	.texto{
		font-size: 14px;
		line-height: 18px;
		color: #5c5c5c;
		margin: 5px 0;
	}
	.botao{
		width: 100%;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background-color: #246ce4;
		color: #fff;
		font-size: 15px;
		font-family: $ops-bold;
		line-height: 1;
		margin-top: 15px;
		transition: all 0.3s ease;
	}

	&:hover{
		border-color: #ededed;
		.botao{
			background-color: $azul;
			color: #fff;
		}
	}
}
// .card-downloads