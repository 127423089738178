.internas{
	.pag-header{
		background-color: #ededed;
		padding: 30px 0;
		ul{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
			text-transform: uppercase;
			font-family: $ops-semibold;
			line-height: 1;
			font-size: 16px;
			color: #1d1d1d;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			text-align: center;
			position: relative;
			padding-bottom: 15px;

			&:before,
			&:after{
				content: '';
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 0;
			}
			&:before{
				background-color: #9e9e9e;
				width: 100px;
				height: 1px;
			}
			&:after{
				background-color: #343434;
				width: 30px;
				height: 3px;
			}

			li + li{
				margin-left: 5px;
				padding-left: 5px;
				border-left: 2px solid #1d1d1d;
			}
		}
	}
	// .pag-header

	.pag-body{
		padding: 50px 0;

		.titulo-pag{
			font-size: 20px;
			color: #1d1d1d;
			font-family: $ops-extrabold;
			line-height: 22px;
			margin-bottom: 15px;
		}

		&.pag-quem-somos{
			padding-bottom: 0;

			.col-xl-7{
				padding-bottom: 30px;
			}
			.bloco-equipe{
				background-color: $azul-claro;
				padding: 40px 0 60px 0;
				position: relative;

				&:before{
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					border-left: 20px solid transparent;
					border-right: 20px solid transparent;
					border-bottom: 20px solid #fff;
				}

				.titulo{
					font-family: $ops-semibold;
					text-transform: uppercase;
					font-size: 20px;
					color: #fff;
					margin-bottom: 15px;
				}
				.desc{
					color: #fff;
				}
			}

			@include media-breakpoint-down(md) {
				figure.col-xl-5{
					text-align: center;
					margin-top: 20px;
				}
			}
		}
		//&.pag-quem-somos

		&.pag-produtos{
			.titulo-pag,
			.desc{
				text-align: center;
			}
			.card-produtos{
				margin-top: 40px;
			}
		}
		// &.pag-produtos

		&.pag-produtos-detalhes{
			.carousel{
				.controles{
					z-index: 2;
					position: absolute;
					a + a{
						margin-left: 5px;
					}
					a{
						z-index: 2;
						color: #000;
						line-height: 1;
						font-size: 26px;
						transition: all 0.2s ease;

						&:hover{
							color: #fff;
						}
					}
				}
			}
			//.carousel

			.bloco-recomendacoes{
				margin-top: 40px;
			}

			@include media-breakpoint-up(lg) {
				.carousel{
					.controles{
						bottom: 10px;
						right: 10px;
					}
				}
			}
			//UP-LG
			@include media-breakpoint-down(md) {
				.col-xl-4{
					order: 2;
					margin-top: 30px;
				}
				.carousel{
					.controles{
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 96%;

						a{
							top: 0;
							position: absolute;
							transform: translateY(-50%);

							&.prev{
								left: 0;
							}
							&.next{
								right: 0;
							}
						}
					}
				}
			}
			//UP-LG
		}
		// &.pag-produtos-detalhes

		&.pag-laudos{
			.botoes{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 25px;
				a{
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					padding: 5px;
					line-height: 1;
					color: #fff;
					font-family: $ops-bold;
					font-size: 15px;
					width: 100%;
					max-width: 180px;
					height: 44px;
					background-color: #246ce4;
					margin: 5px 10px 5px 0;

					&.active{
						background-color: $azul;
					}
				}
			}
			//.botoes

			.form-estilos{
				padding-top: 25px;
				padding-bottom: 40px;
				margin-bottom: 40px;
				border-bottom: 8px solid #ededed;

				.form-control{
					max-width: 570px;
				}
			}
			// .form-estilos

			.group-fispqs{
				margin-top: 25px;
				position: relative;
				width: 100%;
				max-width: 570px;

				.subtitulo{
					font-size: 15px;
					font-family: $ops-bold;
					color: #1d1d1d;
					line-height: 1;
					margin-bottom: 5px;
				}
				.btn-collapse{
					height: 44px;
					background-color: #ededed;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 5px 10px;
					color: #505050;
					font-size: 15px;
					line-height: 1;
					cursor: pointer;
				}
				#dropFichasFispqs{
					position: absolute;
					background-color: #fff;
					padding: 10px;
					top: calc(100% + 10px);
					left: 0;
					list-style-type: none;
					margin-bottom: 0;
					z-index: 10;
					width: 100%;
					max-height: 250px;
					overflow-y: auto;
					box-shadow: 0 0 4px rgba(0,0,0,0.4);
					font-size: 15px;
					font-family: $ops-semibold;
					color: #1d1d1d;

					&::-webkit-scrollbar{
						width: 8px;
						background-color: #ededed;
					}
					&::-webkit-scrollbar-button{
						display: none;
					}
					&::-webkit-scrollbar-thumb{
						background-color: #aaa;
					}

					a:hover{
						text-decoration: underline;
					}
				}
				// #dropFichasFispqs
			}
			// .group-fispqs

			@include media-breakpoint-down(sm) {
				.botoes{
					justify-content: center;
				}
				.titulo-pag{
					text-align: center;
				}
				.btn-padrao{
					margin-right: auto;
					margin-left: auto;
				}
			}

		}
		// &.pag-laudos

		&.pag-documentos{
			.titulo-pag,
			.desc{
				text-align: center;
			}
			.card-downloads{
				margin-top: 40px;
				margin-bottom: 0;
			}
		}
		// &.pag-produtos

		&.pag-curiosidades{
			.titulo-pag,
			.desc{
				text-align: center;
			}
			.card-curiosidade{
				margin-top: 40px;
				margin-bottom: 0;
			}
		}
		// &.pag-produtos

		&.pag-contato{
			.titulo-1{
				margin-bottom: 20px;
			}
			.form-estilos{
				.form-group{
					margin-bottom: 10px;
				}
				.btn-padrao{
					margin-left: auto;
				}
			}
			.desc{
				margin-bottom: 15px;
			}
			.item-contato + .item-contato{
				margin-top: 20px;
			}
			.item-contato{
				display: flex;
				font-size: 14px;

				i.fas{
					font-size: 24px;
					color: #444;
					min-width: 24px;
					text-align: center;
					margin-right: 10px;
				}
			}

			@include media-breakpoint-down(md) {
				.col-lg-6 + .col-lg-6{
					margin-top: 40px;
				}
				.btn-padrao{
					margin-right: auto;
					margin-left: auto;
				}
			}
		}
		// &.pag-contato
	}
	// .pag-body
}
// .internas