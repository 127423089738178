.topo{
	position: sticky;
	top: -38px;
	z-index: 100;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0,0,0,0.4);

	ul{
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
	}

	.logo{
		overflow: hidden;
		a{
			overflow: hidden;
			position: relative;

			&:before{
				content:'';
				display: block;
				width: 50%;
				height: 300%;
				left: -65%;
				top: 50%;
				transform: translateY(-50%) rotate(15deg);
				background: linear-gradient(to right, transparent, #FFF, transparent);
				opacity: 0.5;
				position: absolute;
			}
		}
		
		&.active a{
			&:before{
				left: 100%;
				transition: left 0.3s linear;
			}
		}
	}

	.bar-topo{
		background-color: $azul;
		color: #fff;
		font-family: $ops-semibold;
		font-size: 14px;
		line-height: 14px;
		padding: 10px 0;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.contato-topo{
			display: flex;
			align-items: center;

			i.fas,
			span{
				margin-right: 5px;
			}
			span{
				font-family: $ops-bold;
			}
		}
		.redes-sociais{
			display: flex;
			align-items: center;
			font-size: 18px;

			a:hover{
				color: $azul-claro;
			}

			li + li{
				margin-left: 10px;
			}
		}

		@include media-breakpoint-up(lg) {
			.msg{
				flex-grow: 1;
			}
			.contato-topo{
				margin-right: 10px;
				padding-right: 10px;
				border-right: 2px solid #fff;
			}
		}
		@include media-breakpoint-down(md) {
			.msg{
				display: none;
			}
			.contato-topo{
				span{
					display: none;
				}
			}
		}
	}
	// .bar-topo

	.main-navigation{
		@include media-breakpoint-only(xl) {
			.container{
				display: flex;
			}
			.logo{
				padding: 17px 0;
				margin-right: 100px;
			}
			.main-menu{
				flex-grow: 1;
				display: flex;
				.menu{
					display: flex;
					flex-grow: 1;
					justify-content: space-between;
					.menu-li{
						display: flex;
						.menu-a{
							font-family: $ops-bold;
							display: flex;
							align-items: center;
							font-size: 14px;
							color: #000;
							padding: 5px;
							border-top: 5px solid transparent;

							&:hover{
								border-color: $azul-claro;
								color: $azul-claro;
							}
						}
						&.active .menu-a{
							border-color: $azul-claro;
							color: $azul-claro;
						}
						&.dropdown{

							&:hover{
								.drop-content{
									display: block;
								}
								.item-drop{
									.menu-a{
										border-color: $azul-claro;
										color: $azul-claro;
									}
								}
							}

							.item-drop{
								display: flex;
								a.fas{
									display: none;
								}
							}
							.drop-content{
								position: absolute;
								top: calc(100% - 5px);
								left: -5px;
								width: 220px;
								min-width: 220px;
								background-color: #e4e4e4;
								z-index: 10;
								filter: drop-shadow(0 0 4px rgba(0,0,0,0.2));
								padding: 10px;

								&:before{
									content: '';
									position: absolute;
									bottom: 100%;
									left: 15px;
									border-left: 10px solid transparent;
									border-right: 10px solid transparent;
									border-bottom: 14px solid #e4e4e4;
								}

								li + li{
									margin-top: 10px;
								}
								a{
									display: block;
									color: #282828;
									font-family: $ops-semibold;
									font-size: 15px;
									line-height: 17px;
									padding: 5px;

									&:hover{
										color: #b7b7b7;
									}
								}
							}
						}
					}
				}
			}
		}
		//ONLY DESKTOP

		@include media-breakpoint-down(lg) {
			position: fixed;
			top: 0;
			left: -250px;
			width: 250px;
			height: 100%;
			padding-bottom: 15px;
			background: linear-gradient(135deg, rgba(4,47,118,1) 0%,rgba(64,116,182,1) 100%);
			border-right: 1px solid $azul;
			z-index: 1000;
			overflow-y: auto;
			transition: all 0.4s ease;

			&.active{
				left: 0;
			}

			.container{
				padding: 0;
			}

			.logo{
				display: none;
			}

			.main-menu{
				.menu{
					.menu-li{
						color: #fff;

						.menu-a{
							display: block;
							padding: 10px;
							font-family: $ops-bold;
							flex-grow: 1;
						}
						&:hover,
						&.active{
							background-color: #e4e4e4;
							color: $azul;
						}
						&.dropdown{
							.item-drop{
								display: flex;
								justify-content: space-between;

								a.fas{
									color: inherit;
									width: 40px;
									display: flex;
									align-items: center;
									justify-content: center;

									&:before{
										transition: transform 0.2s linear;
									}
								}
								a.fas[aria-expanded="true"] {
									&:before{
										transform: rotate(180deg);
									}
								}
							}
							.drop-content{
								background-color: #333;
								color: #fff;
								li + li{
									border-top: 1px solid #e4e4e4;
								}
								a{
									display: block;
									padding: 10px 5px 10px 15px;
									font-size: 14px;

									&:hover{
										background-color: lighten(#333, 10%);
									}
								}
							}
						}
					}
				}
			}
		}
		//DOWN LG
	}
	//.main-navigation

	.mbl-controls{
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			.logo{
				padding: 10px 0;
			}
			.btn-menu{
				font-size: 16px;
				color: #000;
				display: block;
				padding: 10px 0 10px 20px;
			}
		}		

		@include media-breakpoint-down(sm) {
			.logo{
				width: 200px;
			}
		}
		@include media-breakpoint-only(xl) {
			display: none;
		}
	}
}