.rodape{
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
	.conteudo-rodape{
		padding: 30px 0;
		text-align: center;
		.logo{
			margin-bottom: 30px;
		}
		i.fas{
			color: #000;
			margin-bottom: 10px;
			font-size: 20px;
		}
		.informacao{
			font-size: 14px;
			color: #1d1d1d;
			line-height: 18px;

			a:hover{
				text-decoration: underline;
			}

			a + a{
				margin-left: 5px;
				padding-left: 5px;
				position: relative;

				&:before{
					content: '';
					position: absolute;
					left: 0;
					width: 1px;
					height: 14px;
					top: 50%;
					transform: translateY(-50%);
					background-color: #1d1d1d;
				}
			}
		}
		//.informacao

		.table-cotacoes{
			width: 100%;
			max-width: 800px;
			margin-right: auto;
			margin-left: auto;
			margin-top: 30px;
			border: 1px solid #888;
			background-color: #ededed;

			.item-cotacao + .item-cotacao{
				border-top: 1px solid #888;
			}

			.item-cotacao{
				.moeda,
				.iframe{
					padding: 10px;
					line-height: 1;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.moeda{
					
					line-height: 1;
					color: #1d1d1d;
					font-size: 14px;
				}
			}

			@include media-breakpoint-up(md) {
				.item-cotacao{
					display: flex;
					.moeda,
					.iframe{
						flex: 1;
					}
					.moeda{
						border-right: 1px solid #888;
					}
				}
			}
		}
		// .table-cotacoes

		@include media-breakpoint-down(md) {
			.col-lg-4 + .col-lg-4{
				margin-top: 20px;
			}
		}
	}
	//conteudo-rodape

	.autorais{
		background-color: #246ce4;
		padding: 20px 0;
		font-size: 10px;
		color: #fff;
		line-height: 10px;

		a{
			display: inline-flex;
			align-items: center;
			margin-top: 10px;

			svg{
				font-size: 24px;
				margin-left: 5px;
			}
		}

		@include media-breakpoint-down(md) {
			text-align: center;

			a{
				justify-content: center;
			}
		}
	}
}