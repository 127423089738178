.main-home-hidden {
	overflow: hidden;
	width: 100%;
	max-width: 1920px;
}
.banners{
	width: 100%;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;

	.controles{
		z-index: 2;
		position: absolute;

		a{
			z-index: 2;
			color: #000;
			line-height: 1;
			color: #fff;
			font-size: 30px;
			transition: all 0.2s ease;

			&:hover{
				color: $azul-claro;
			}
		}
	}
	// .controles

	@include media-breakpoint-only(xl) {
		.carousel{
			min-width: 1920px;
			left: 50%;
			position: relative;
			transform: translateX(-50%);
			.carousel-inner{
				z-index: 2;
				.carousel-item{
					z-index: 2;
				}
			}
		}
		.controles{
			bottom: 30px;
			right: 0;
			margin-right: calc(calc(100% - 1110px)/2);

			a + a{
				margin-left: 5px;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		.controles{
			bottom: 20px;
			right: 0;
			margin-right: calc(calc(100% - 930px)/2);

			a + a{
				margin-left: 5px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.controles{
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			a{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
			}

			a.prev{
				left: 0;
			}
			a.next{
				right: 0;
			}
		}
	}
	@include media-breakpoint-only(md) {
		.controles{
			width: 690px;
		}
	}
	@include media-breakpoint-only(sm) {
		.controles{
			width: 510px;
		}
	}
	@include media-breakpoint-only(xs) {
		.controles{
			width: 90%;
		}
	}
}
// .banners

.quem-somos{
	padding-top: 40px;

	.container{
		border-bottom: 2px solid #e4e4e4;
		padding-bottom: 40px;
	}

	.img{
		border: 15px solid #fff;
		width: 100%;
		max-width: 450px;
		margin-right: auto;
		margin-left: auto;
	}
	.desc{
		margin: 15px 0 20px 0;
	}

	@include media-breakpoint-only(xl) {
		.col-xl-7{
			padding-top: 110px;
		}
	}
	@include media-breakpoint-only(lg) {
		.col-xl-7{
			padding-top: 90px;
		}
	}
	@include media-breakpoint-up(lg) {
		position: relative;
		z-index: 1;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 250px;
			background-color: $azul;
			z-index: -1;
		}

		.col-xl-5{

		}
		.col-xl-7{
			.titulo-2{
				margin-bottom: 90px;
				color: #fff;
			}
		}
	}
	@include media-breakpoint-down(md) {
		hgroup{

			.titulo-2{
				color: #1d1d1d;
				margin-bottom: 10px;
			}
		}
		.btn-padrao{
			margin-right: auto;
			margin-left: auto;
		}
		.col-xl-5{
			order: 2;
			text-align: center;
			margin-top: 30px;

			.img{
				border-color: $azul;
			}
		}
	}
}
// .quem-somos

.produtos{
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 1920px;
	overflow: hidden;

	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		min-width: 1920px;
		height: 350px;
		background-color: $azul;
		z-index: -1;
		background-repeat: no-repeat;
		background-position: right top;
		background-image: url('../images/bg-produtos.png');
		overflow: hidden;
	}

	.titulo-2{
		color: #1d1d1d;
		margin-bottom: 10px;
	}
	.btn-padrao{
		margin: 40px auto 0 auto;
		color: #fff;
		border-color: #fff;
	}
	.segura-carousel{
		background-color: #fff;
		padding: 50px 0 30px 0;
		position: relative;

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 0;
			width: 30px;
			height: 100%;
			background-color: inherit;
		}
		&:before{
			left: 100%;
		}
		&:after{
			right: 100%;
		}

		.owl-carousel{
			.owl-nav{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				width: 100%;

				.owl-prev,
				.owl-next{
					position: absolute;
					top: 0;
					transform: translateY(-50%);
					line-height: 1;
					font-size: 26px;
					color: #000;
					transition: all 0.2s linear;

					&:hover{
						color: $azul;
					}
				}
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.segura-carousel{
			.owl-carousel{
				.owl-nav{
					.owl-prev{
						left: -20px;
					}
					.owl-next{
						right: -20px;
					}
				}
			}
		}
	}
	@include media-breakpoint-only(lg) {
		.segura-carousel{
			.owl-carousel{
				.owl-nav{
					.owl-prev{
						left: -5px;
					}
					.owl-next{
						right: -5px;
					}
				}
			}
		}
	}
	@include media-breakpoint-only(md) {
		.segura-carousel{
			.owl-carousel{
				.owl-nav{
					.owl-prev{
						left: 0px;
					}
					.owl-next{
						right: 0px;
					}
				}
			}
		}
	}
	@include media-breakpoint-only(sm) {
		.segura-carousel{
			.owl-carousel{
				.owl-nav{
					.owl-prev{
						left: -20px;
					}
					.owl-next{
						right: -20px;
					}
				}
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.segura-carousel{
			.owl-carousel{
				.owl-nav{
					max-width: 300px;
					.owl-prev{
						left: 0px;
					}
					.owl-next{
						right: 0px;
					}
				}
			}
		}
	}
	@include media-breakpoint-up(lg) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	@include media-breakpoint-down(md) {
		padding-top: 40px;
		padding-bottom: 40px;

		.titulo-1{
			margin-bottom: 20px;
		}
	}
}
// .produtos

.novidades{
	position: relative;
	z-index: 2;
	width: 100%;
	max-width: 1920px;
	overflow: hidden;

	.titulo-2{
		color: #1d1d1d;
	}
	.desc{
		margin: 10px 0 20px 0;
	}
	.carousel{
		.controles{
			z-index: 2;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;

			a{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				z-index: 2;
				color: #000;
				line-height: 1;
				color: #000;
				font-size: 26px;
				transition: all 0.2s ease;

				&:hover{
					color: $azul-claro;
				}
			}
		}
	}
	//.carousel

	@include media-breakpoint-only(xl) {
		.segura-conteudo{
			width: calc(52.7% - calc(calc(100vw - 1155px)/2));
		}
	}
	@include media-breakpoint-only(lg) {
		.segura-conteudo{
			width: calc(52.7% - calc(calc(100vw - 975px)/2));
		}
	}
	@include media-breakpoint-up(lg) {
		.segura-imagem{
			width: 47.3%;
			display: flex;
			justify-content: flex-end;
		}
		.segura-conteudo{
			padding: 30px 35px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.carousel{
				.controles{
					a{
						&.prev{
							left: -25px;
						}
						&.next{
							right: -25px;
						}
					}
				}
			}
			//.carousel
		}
	}
	//UP-LG
	@include media-breakpoint-down(md) {
		.segura-conteudo{
			order: 1;
			width: 100%;
			padding: 40px 15px;
			margin-right: auto;
			margin-left: auto;

			.carousel{
				padding: 0 25px;
				.controles{
					a{
						&.prev{
							left: 0px;
						}
						&.next{
							right: 0px;
						}
					}
				}
			}
			//.carousel
		}
		.segura-imagem{
			width: 100%;
			order: 2;
			img{
				max-width: 100%;
				height: auto;
			}
		}
	}
	@include media-breakpoint-only(md) {
		.segura-conteudo{
			max-width: 720px
		}
	}
	@include media-breakpoint-only(sm) {
		.segura-conteudo{
			max-width: 540px
		}
	}

}
// .novidades

.curiosidades{
	position: relative;
	z-index: 1;

	&:before{
		content: '';
		position: absolute;
		z-index: -1;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		min-width: 1920px;
		height: 860px;
		background-position: right bottom;
		background-repeat: no-repeat;
		background-image: url('../images/bg-curiosidades.png');
	}

	.titulo-2{
		color: #1d1d1d;
		font-size: 22px;
	}
	.btn-padrao{
		margin: 40px auto 0 auto;
	}
	.box-curiosidade{
		padding: 20px;
		position: relative;
		box-shadow: 0 0 10px rgba(0,0,0,0.2);
		margin-top: 25px;

		.link{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.data{
			font-size: 14px;
			color: #5c5c5c;
			line-height: 1;
			margin-bottom: 10px;
			font-family: $ops-italic;
			display: flex;
			align-items: center;

			i.fas{
				color: #000;
				margin-right: 5px;
			}
		}
		.nome{
			font-family: $ops-extrabold;
			font-size: 18px;
			line-height: 22px;
			color: #1d1d1d;
			transition: all 0.2s linear;
		}

		&:hover{
			.nome{
				color: #246ce4;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 60px 0;

		hgroup.down-md{
			display: none;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 40px 0;
		hgroup.down-md{
			margin-bottom: 30px;
		}
		hgroup.up-lg{
			display: none;
		}
	}
}
// .curiosidades

.form__recomend{
	position: relative;
	padding: 0px 0px 150px 0px;
	overflow: hidden;

	hgroup{
		max-width: 490px;
		margin-bottom: 40px;
	}

	&:after{
		width: 100vw;
		left: 50%;
		content: '';
		transform: translateX(-50%);
		position: absolute;
		height: 360px;
		background-color: #001d55;
		bottom: 0px;
		z-index: -1;
	}

	.container{
		background-color: #fff;
		padding: 50px;
		z-index: 0;

		@include media-breakpoint-down (md) {
			padding: 40px 15px;
		}
	}
}
.contato__input{
	background-color: #e6e6e6;
	color: #000;
	width: 100%;
	display: block;
	min-height: 50px;
	border: none;
	padding: 14px 16px;
}
.recoment__form{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 15px;

	@include media-breakpoint-down (sm) {
		display: block;
	}

	.form-group{
		margin-bottom: 15px;
	}
	.colspan{
		grid-column: span 2;
	}

	button{
		background-color: transparent;
	}
}
.btn__center{
	display: flex;
	justify-content: center;
}
