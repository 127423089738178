// Margins
@include margin-variant(30);
@include margin-variant(40);
@include margin-variant(60);
@include margin-variant(70);

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}


.slick-slider:not(.slick-initialized) {
	opacity: 0;
}

#loading-page{
	position: fixed;
	z-index: 2000;
	width: 100%;
	height: 100%;
	background: #ededed;
	left:0;
	top:0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 30px;

	.gooey{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 142px;
		height: 40px;
		margin: -20px 0 0 -71px;
		background: transparent;

		.dot{
			position: absolute;
			width: 16px;
			height: 16px;
			top: 12px;
			left: 15px;
			background: $azul;
			border-radius: 50%;
			transform: translateX(0);
			animation: dot 2.8s infinite;
		}
		.dots{
			transform: translateX(0);
			margin-top: 12px;
			margin-left: 31px;
			animation: dots 2.8s infinite;
			span{
				display: block;
				float: left;
				width: 16px;
				height: 16px;
				margin-left: 16px;
				background: $azul;
				border-radius: 50%;
			}
		}
	}

	@keyframes dot{
		50% {
			transform: translateX(96px);
		}
	}
	@keyframes dots{
		50% {
			transform: translateX(-31px);
		}
	}
}

