.btn-pill{
	border-radius: 2rem;
}
.btn-padrao{
	width: 100%;
	max-width: 220px;
	height: 54px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	font-size: 14px;
	font-family: $ops-bold;
	color: $azul;
	border: 2px solid $azul;
	transition: all 0.3s ease-out;

	&:hover{
		color: $azul-claro;
		border-color: $azul-claro;
	}
}