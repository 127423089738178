@font-face {
    font-family: 'open-sans-bold';
    font-display: auto;
    src: url('../fonts/OpenSans-Bold/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Bold/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold/OpenSans-Bold.woff') format('woff'),
        url('../fonts/OpenSans-Bold/OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/OpenSans-Bold/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'open-sans-extrabold';
    font-display: auto;
    src: url('../fonts/OpenSans-ExtraBold/OpenSans-ExtraBold.eot');
    src: url('../fonts/OpenSans-ExtraBold/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-ExtraBold/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBold/OpenSans-ExtraBold.woff') format('woff'),
        url('../fonts/OpenSans-ExtraBold/OpenSans-ExtraBold.ttf') format('truetype'),
        url('../fonts/OpenSans-ExtraBold/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'open-sans-italic';
    font-display: auto;
    src: url('../fonts/OpenSans-Italic/OpenSans-Italic.eot');
    src: url('../fonts/OpenSans-Italic/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Italic/OpenSans-Italic.woff2') format('woff2'),
        url('../fonts/OpenSans-Italic/OpenSans-Italic.woff') format('woff'),
        url('../fonts/OpenSans-Italic/OpenSans-Italic.ttf') format('truetype'),
        url('../fonts/OpenSans-Italic/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'open-sans-regular';
    font-display: auto;
    src: url('../fonts/OpenSans-Regular/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Regular/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular/OpenSans-Regular.woff') format('woff'),
        url('../fonts/OpenSans-Regular/OpenSans-Regular.ttf') format('truetype'),
        url('../fonts/OpenSans-Regular/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open-sans-semibold';
    font-display: auto;
    src: url('../fonts/OpenSans-SemiBold/OpenSans-SemiBold.eot');
    src: url('../fonts/OpenSans-SemiBold/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-SemiBold/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBold/OpenSans-SemiBold.woff') format('woff'),
        url('../fonts/OpenSans-SemiBold/OpenSans-SemiBold.ttf') format('truetype'),
        url('../fonts/OpenSans-SemiBold/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}