body{
	font-family: $ops-regular;
}
body,
html{
	width: 100%;
	min-height: 100vh;
}
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.wrapper{
		flex-grow: 1;
	}
}
.conteudo__gerenciavel{
	padding-top: 30px;
}
a{
	color: inherit;

	&:hover,
	&:focus{
		color: inherit;
		text-decoration: none;
	}
}
textarea{
	resize: none;
}
p, h1, h2, h3, h4, h5, h6, figure{
	margin-bottom: 0;
}
img[style] {
	max-width: 100%;
	height: auto;
}
.bg-menu{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	z-index: 900;
}
.titulo-1{
	font-size: 16px;
	line-height: 20px;
	font-family: $ops-bold;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	display: inline-flex;
	color: $azul-claro;

	&:before{
		content: '';
		position: relative;
		display: block;
		width: 68px;
		height: 3px;
		background-color: currentColor;
		margin: auto 10px auto 0;
	}
}
.titulo-2{
	font-size: 24px;
	text-transform: uppercase;
	line-height: 26px;
	font-family: $ops-extrabold;
}
.desc{
	font-size: 14px;
	line-height: 18px;
	color: #5c5c5c;

	a:hover{
		text-decoration: underline;
	}

	img{
		max-width: 100%;
		height: auto;
	}
	iframe{
		max-width: 100%;
	}

	&.editor{
		p + p{
			margin-top: 1em;
		}
	}
}
*[data-animate] {
	opacity: 0;
}
.animated[data-animate] {
	opacity: 1;
}