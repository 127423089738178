.paginacao{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
	list-style-type: none;
	margin-bottom: 0;
	padding-left: 0;
	font-family: $ops-semibold;

	.active a{
		background-color: #ddd;
		box-shadow: none;
		pointer-events: none;

		&:hover{
			background-color: #ededed;
		}
	}

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		box-shadow: 0 0 4px rgba(0,0,0,0.4);
		background-color: #fff;
		color: #1d1d1d;
		font-size: 16px;
		line-height: 1;

		&:hover{
			background-color: #ededed;
		}
	}
	li + li{
		margin-left: 6px;
	}

}
