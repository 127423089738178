.form-estilos{
	select.form-control,
	input.form-control{
		height: 44px;
	}
	.form-control{
		background-color: #ededed;
		border-radius: 0;
		border: 1px solid transparent;
		font-size: 15px;
		color: #505050;

		&:focus{
			border-color: $azul;
			box-shadow: none;
		}
		&::placeholder{
			color: #505050;
		}
	}
	button{
		background-color: #fff;
		outline: none;
	}
}